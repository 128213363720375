<template>
  <div>
    <section
      id="filter-section"
      class="bg-white p-3 rounded shadow-sm"
    >
      <b-row class="align-items-center">
        <b-col cols="6">
          <b-form-group
            label="Tanggal Mulai"
            label-for="start-date"
          >
            <b-form-input
              id="start-date"
              v-model="selectedAftapDate"
              type="date"
              @input="fetchSchedule"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Pilih Lokasi MU"
            label-for="input-lokasi"
          >
            <b-form-select
              id="input-lokasi"
              v-model="selectedMU"
              class="mb-1"
              placeholder="Pilih Lokasi"
              :options="options"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-end">
        <b-button
          variant="primary"
          @click="filterData"
        >
          Filter
        </b-button>
      </div>
    </section>

    <section
      id="print-section"
      class="mt-3 bg-white p-3 rounded shadow-sm"
      style="position: relative"
    >
      <b-button
        id="print-button"
        style="position: absolute; top: 0; right: 0; margin: 10px"
        variant="outline-primary"
        @click="print()"
      >
        <i class="ri-printer-line mr-1" />
        Cetak
      </b-button>
      <div>
        <h3 class="text-center">
          Daftar Screening UTD RS OTISTA
        </h3>
        <p class="text-center">
          Periode {{ selectedAftapDate }} - MU <span class="text-bold">{{ selectedMUText }}</span>
        </p>
      </div>
      <h5 class="text-center m-0 p-1 border">
        Jumlah Reaktif
      </h5>
      <table class="table table-sm table-bordered">
        <tr class="text-center">
          <td>
            <p>Syphilis</p>
            {{ screening.reactive_calculations.syphilis_positive_count }}
          </td>
          <td>
            <p>Hepatitis B</p>
            {{ screening.reactive_calculations.hepatitis_b_positive_count }}
          </td>
          <td>
            <p>hepatitis C</p>
            {{ screening.reactive_calculations.hepatitis_c_positive_count }}
          </td>
          <td>
            <p>HIV AIDS</p>
            {{ screening.reactive_calculations.hiv_aids_positive_count }}
          </td>
        </tr>
      </table>
      <b-table
        striped
        hover
        small
        show-empty
        :items="screening.items"
        :fields="screening.fields"
      >
        <template #cell(syphilis)="data">
          <p :class="data.item.syphilis === 1 ? 'text-danger' : 'text-dark'">
            {{ data.item.syphilis === 1 ? "Reaktif" : "Non Reaktif" }}
          </p>
        </template>
        <template #cell(hepatitis_b)="data">
          <p :class="data.item.hepatitis_b === 1 ? 'text-danger' : 'text-dark'">
            {{ data.item.hepatitis_b === 1 ? "Reaktif" : "Non Reaktif" }}
          </p>
        </template>
        <template #cell(hepatitis_c)="data">
          <p :class="data.item.hepatitis_c === 1 ? 'text-danger' : 'text-dark'">
            {{ data.item.hepatitis_c === 1 ? "Reaktif" : "Non Reaktif" }}
          </p>
        </template>
        <template #cell(hiv_aids)="data">
          <p :class="data.item.hiv_aids === 1 ? 'text-danger' : 'text-dark'">
            {{ data.item.hiv_aids === 1 ? "Reaktif" : "Non Reaktif" }}
          </p>
        </template>
      </b-table>
    </section>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
import {
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
} from 'bootstrap-vue'

import html2pdf from 'html2pdf.js'
import screeningAPI from '../../../api/screening/screeningAPI'
import manageBloodvanAPI from '../../../api/managebloodvan/manageBloodvanAPI'

const screening = ref({
  fields: [
    { key: 'barcode', label: 'Barcode' },
    { key: 'syphilis', label: 'Syphilis' },
    { key: 'hepatitis_b', label: 'Hepatitis B' },
    { key: 'hepatitis_c', label: 'Hepatitis C' },
    { key: 'hiv_aids', label: 'HIV AIDS' },
    { key: 'nat', label: 'NAT' },
  ],
  items: [],
  reactive_calculations: {
    syphilis_positive_count: 0,
    hepatitis_b_positive_count: 0,
    hepatitis_c_positive_count: 0,
    hiv_aids_positive_count: 0,
    non_reactive_count: 0,
  },
})

const selectedAftapDate = ref(null)
const selectedEndDate = ref(null)
const selectedMU = ref(null)
const selectedMUText = ref('')
const scheduleList = ref([])
const options = ref([{ value: null, text: 'Pilih Lokasi MU' }])

async function getScreening(params = {}) {
  const { data: response } = await screeningAPI.getReport(params)
  screening.value.items = response.data
  screening.value.reactive_calculations = response.reactive_calculations
}

function filterData() {
  const params = {}
  if (selectedAftapDate.value) params.aftap_date = selectedAftapDate.value

  const selectedLocationId = parseInt(selectedMU.value)
  const selectedLocation = Object.values(scheduleList.value).find(
    location => location.id === selectedLocationId,
  )

  if (selectedLocation) {
    if (selectedLocation.type === 'hospital') {
      // set payload ke hospital_id
      params.hospital_id = selectedLocation.id
    } else if (selectedLocation.type === 'blood_van') {
      // set payload ke blood_van_id
      params.blood_van_id = selectedLocation.id
    }
  }

  getScreening(params)
}

async function fetchSchedule() {
  if (!selectedAftapDate.value) return

  try {
    const response = await manageBloodvanAPI.listSchedule({
      date: selectedAftapDate.value,
    })

    scheduleList.value = response.data.data
    listOptions()
  } catch (error) {
    console.log(`${error}Error Fetching Schedule`)
  }
}

function listOptions() {
  options.value = [
    { value: null, text: 'Pilih Lokasi' },
    ...scheduleList.value.map(item => ({
      value: item.id,
      text: item.location,
    })),
  ]
}

function print() {
  const printSection = document.getElementById('print-section')
  const printButton = document.getElementById('print-button')

  // Hide the print button
  printButton.style.display = 'none'

  const opt = {
    margin: 0,
    filename: `laporan_screening_${selectedAftapDate.value}_${selectedMUText.value}.pdf`,
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
  }

  // Generate PDF and then show the button again
  html2pdf()
    .set(opt)
    .from(printSection)
    .save()
    .then(() => {
      // Restore the print button visibility
      printButton.style.display = 'block'
    })
}

watch(selectedMU, (newValue) => {
  const selectedOptions = options.value.find(option => option.value === newValue)
  selectedMUText.value = selectedOptions ? selectedOptions.text : ""
})

onMounted(() => {
  getScreening(params)
})
</script>

<style scoped>
@media print {
  #print-section {
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0;
  }
  #print-button {
    display: none;
  }
}
</style>
